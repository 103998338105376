<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">通知信息列表</h1>
    </div>
    <div slot="extra">
      <a-button type="primary" @click="handleAdd">发布通知</a-button>
    </div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-form-item label="标题">
            <a-input v-model="searchParams.title" @change="search" placeholder="请输入标题"/>
          </a-form-item>
          <a-form-item label="发布人">
            <a-select
              allowClear showSearch optionFilterProp="title"
              v-model="searchParams.publisher"
              placeholder="请选择发布人"
              @change="search">
              <a-select-option :key="item.id"
                               :value="item.id"
                               :title="item.name"
                               v-for="item in usersData">
                {{item.name}}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="f-r m-r-none">
            <a-button type="primary" @click="search" icon="search">查询</a-button>
            <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table bordered size="default"
               :rowKey="record => record.id"
               :columns="columns"
               @change="tableChange"
               :pagination="pagination"
               :dataSource="tableData"
               :loading="tableLoading"
               :scroll="{ x: 1440 }">
        <template slot="_index" slot-scope="text, record, index">{{index+1}}</template>
        <template slot="receiveType" slot-scope="text, record">
          <a-tag v-if="record.receiveType==='all'">全部</a-tag>
          <a-tag v-if="record.receiveType==='dept'" color="green">部门</a-tag>
          <a-tag v-if="record.receiveType==='user'" color="red">用户</a-tag>
        </template>
        <template slot="ifCheck" slot-scope="text, record">
          <a-tag v-if="record.ifCheck==='unCheck'">未审核</a-tag>
          <a-tag v-if="record.ifCheck==='agree'" color="green">审核通过</a-tag>
          <a-tag v-if="record.ifCheck==='disagree'" color="red">审核不通过</a-tag>
        </template>
        <template slot="readNum" slot-scope="text, record">
          <a @click="readDetailClick(record.id)">
            <span style="color: red;">{{record.readNum}}</span>
            <span>/{{record.receiveNum}}</span>
          </a>
        </template>
        <template slot="action" slot-scope="text, record">
          <a-button @click="handleApproval(record.id)" shape="circle" icon="file-done" size="small"></a-button>
          <a-divider type="vertical"/>
          <a-button v-if="record.ifCheck==='unCheck'" @click="handleEdit(record.id)" shape="circle" icon="edit"
                    size="small"></a-button>
          <a-divider v-if="record.ifCheck==='unCheck'" type="vertical"/>
          <a-button v-if="record.ifCheck==='unCheck'" type="danger" @click="handleDelete(record.id)" shape="circle"
                    icon="delete" size="small"></a-button>
          <a-divider v-if="record.ifCheck==='unCheck'" type="vertical"/>
          <a-button @click="handleView(record.id)" shape="circle" icon="menu" size="small"></a-button>
        </template>
      </a-table>
      <a-modal title="发布通知"
               width="900px"
               :visible="addModal"
               :maskClosable="false"
               @ok="handleAddOk"
               @cancel="closeAddModal">
        <notice-form ref="addRef" type="add"
                     @addSuccess="addSuccess"
                     @addError="addError">
        </notice-form>
      </a-modal>
      <a-modal title="修改通知信息"
               width="900px"
               :visible="editModal"
               :maskClosable="false"
               @ok="handleEditOk"
               @cancel="closeEditModal">
        <notice-form ref="editRef" type="edit"
                     @editSuccess="editSuccess"
                     @editError="editError">
        </notice-form>
      </a-modal>
      <a-modal title="审批通知信息"
               width="900px"
               :visible="approvalModal"
               :maskClosable="false"
               @ok="handleApprovalAgree"
               @cancel="handleApprovalCancel">
        <approval-form ref="approvalRef"
                       @approvalSuccess="approvalSuccess"
                       @approvalError="approvalError">
        </approval-form>
        <div slot="footer">
          <a-button type="primary" size="small" @click="handleApprovalAgree">同意</a-button>
          <a-button type="primary" size="small" @click="handleApprovalDisagree">不同意</a-button>
        </div>
      </a-modal>
      <a-drawer
        title="通知信息详情"
        width="600"
        placement="right"
        :closable="true"
        @close="detailClose"
        :visible="detailDrawer">
        <notice-detail ref="viewRef"></notice-detail>
      </a-drawer>
    </a-card>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import entityCRUD from '@/views/common/mixins/entityCRUD'
  import SERVICE_URLS from '../../../api/service.url'
  import { noticeColumns } from './common/common'
  import NoticeForm from './Form'
  import ApprovalForm from './ApprovalForm'
  import NoticeDetail from './Detail'

  export default {
    name: 'platNotice',
    mixins: [entityCRUD],
    components: { NoticeDetail, PageLayout, NoticeForm, ApprovalForm },
    data () {
      return {
        //审批
        approvalModal: false,
        entityBaseUrl: SERVICE_URLS.platBase.notice,
        searchParams: {
          number: '0',
          size: '10',
          direction: '',
          order: '',
          title: '',
          publisher: null
        },
        initColumns: noticeColumns(),
        usersData: [] //用户下拉数据
      }
    },
    created () {
      this.search()
      this.loadUsersData()
    },
    computed: {},
    methods: {
      //是否已读点击查看详情
      readDetailClick (id) {
        console.info('?????????', id)
      },
      //审批
      handleApproval (id) {
        this.approvalModal = true
        this.$nextTick(() => {
          this.$refs['approvalRef'].loadData(id)
        })
      },
      handleApprovalAgree () {
        this.$nextTick(() => {
          this.$refs['approvalRef'].approvalNotice('agree')
        })
      },
      handleApprovalDisagree () {
        this.$nextTick(() => {
          this.$refs['approvalRef'].approvalNotice('disagree')
        })
      },
      handleApprovalCancel () {
        this.approvalModal = false
      },
      approvalSuccess () {
        this.approvalModal = false
        this.search()
      },
      approvalError () {
        this.approvalModal = false
        this.$message.warning('审批失败')
        this.search()
      },
      loadUsersData () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.usersData = data.body
          }
        })
      }
    }
  }
</script>
