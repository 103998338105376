export const noticeColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '标题',
      align: 'left',
      dataIndex: 'title'
    },
    {
      title: '发布人',
      align: 'center',
      width: '120px',
      dataIndex: 'publisher.name'
    },
    {
      title: '发布时间',
      align: 'center',
      width: '180px',
      dataIndex: 'publishTime'
    },
    {
      title: '接收类型',
      align: 'center',
      width: '100px',
      dataIndex: 'receiveType',
      scopedSlots: { customRender: 'receiveType' }
    },
    {
      title: '接收对象',
      align: 'left',
      key: 'receiveNames',
      minWidth: '240px',
      dataIndex: 'receiveNames'
    },
    {
      title: '审批部门',
      align: 'left',
      width: '140px',
      dataIndex: 'checkName'
    },
    {
      title: '审批状态',
      align: 'center',
      width: '100px',
      dataIndex: 'ifCheck',
      scopedSlots: { customRender: 'ifCheck' }
    },
    {
      title: '是否已读',
      align: 'center',
      width: '100px',
      scopedSlots: { customRender: 'readNum' }
    },
    {
      title: '操作',
      align: 'center',
      width: '180px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

export const noticeStateColumns = () => {
  return [
    {
      title: '序号',
      width: '80px',
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '标题',
      align: 'left',
      dataIndex: 'notice.title'
    },
    {
      title: '发布人',
      align: 'center',
      width: '120px',
      dataIndex: 'notice.publisher.name'
    },
    {
      title: '发布时间',
      align: 'center',
      width: '180px',
      dataIndex: 'notice.publishTime'
    },
    {
      title: '接收类型',
      align: 'center',
      width: '100px',
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '审批部门',
      align: 'left',
      width: '140px',
      dataIndex: 'notice.checkName'
    },
    {
      title: '是否已读',
      align: 'center',
      width: '100px',
      dataIndex: 'ifRead',
      scopedSlots: { customRender: 'ifRead' }
    },
    {
      title: '操作',
      align: 'center',
      width: '100px',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

//是否需要回复
export const ifAnswer = function () {
  return [
    {
      value: 'yes',
      label: '是'
    },
    {
      value: 'no',
      label: '否'
    }
  ]
}

//接收类型
export const receiveTypeData = function () {
  return [
    {
      value: 'all',
      label: '全部'
    },
    {
      value: 'dept',
      label: '部门'
    },
    {
      value: 'user',
      label: '用户'
    }
  ]
}