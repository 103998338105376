<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-form-item label="标题"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input v-decorator="['title', {rules: [{ required: true, message: '请输入标题'}]}]"
                 placeholder="请输入标题"></a-input>
      </a-form-item>
      <a-form-item label="发布时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker format="YYYY-MM-DD" style="width: 100%"
                       v-decorator="['publishTime', {rules: [{ required: true, message: '请选择发布时间'}]}]"
                       placeholder="请选择发布时间"></a-date-picker>
      </a-form-item>
      <a-form-item label="简述"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4"
                 v-decorator="['resume']"
                 placeholder="请输入简述"/>
      </a-form-item>
      <a-form-item label="内容"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <text-rich v-decorator="['content']"
                   style="border: 1px solid #d9d9d9;border-radius: 5px;margin-top: 20px; height: 100%"></text-rich>
      </a-form-item>
      <a-form-item label="图片"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef" @getImageData="getImageData"
          :document-dir-id="21"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
      <a-form-item label="附件"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <qiniu-file-upload
          ref="qiniuFileRef" @getFileData="getFileData"
          :document-dir-id="20"
          :multiSelection="true"
          type="edit"
          :items="formItem.files"
        ></qiniu-file-upload>
      </a-form-item>
      <a-form-item label="接收类型"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收类型" @change="receiveTypeChange"
                  v-decorator="['receiveType', {rules: [{ required: true, message: '请选择接收类型'}]}]">
          <a-select-option
            v-for="item in ReceiveTypeData"
            :key="item.label"
            :value="item.value"
            :label="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="接收对象" v-if="formItem.notice.receiveType!=='all'"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择接收对象" mode="multiple"
                  v-decorator="['receiveIds',]">
          <a-select-option
            v-for="item in receiveData"
            :key="item.id"
            :value="item.id"
            :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="审批部门"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-select placeholder="请选择审批部门"
                  v-decorator="['checkId', {rules: [{ required: true, message: '请选择审批部门'}]}]">
          <a-select-option
            v-for="item in selfDepts"
            :key="item.id"
            :value="item.id"
            :label="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="需要回复"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-radio-group :options="IfAnswer"
                       v-decorator="['ifAnswer',{initialValue: 'no',rules:[{type:'string',required:true,message:'需要回复为必填项'}],trigger:'change'}]">
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '../../../api/service.url'
  import { noticeVO } from './common/noticeVO'
  import { ifAnswer, receiveTypeData } from './common/common'
  import moment from 'moment'
  import TextRich from '../../../components/TextRich/TextRich'
  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '../../../components/QiniuUpload/QiniuFileUpload'

  export default {
    name: 'noticeForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    components: { TextRich, QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        spinShow: false,
        ifSub: false,
        form: this.$form.createForm(this),
        formItem: noticeVO(),
        IfAnswer: ifAnswer(),
        ReceiveTypeData: receiveTypeData(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        receiveData: [], //接收对象
        usersData: [] //当前单位下所有用户
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      },
      selfUnits () {
        return this.$store.getters.selfUnits
      },
      selfDepts () {
        return this.$store.getters.selfDepts
      }
    },
    mounted () {
    },
    created () {
      this.formItem.notice.publisher.id = this.currentUser.id
      this.loadUsersData()
    },
    methods: {
      //接收类型改变
      receiveTypeChange (e) {
        this.formItem.notice.receiveType = e
        this.formItem.notice.receiveIds = []
        this.form.setFieldsValue({
          receiveIds: []
        })
        if (this.formItem.notice.receiveType === 'all') {
          this.receiveData = []
        } else if (this.formItem.notice.receiveType === 'dept') {
          this.receiveData = this.selfDepts
        } else {
          this.receiveData = this.usersData
        }
      },
      loadData (id) {
        //清空表单数据
        this.formItem = noticeVO()
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.platBase.notice.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.$nextTick(() => {
              this.setFields(data.body)
            })
            //赋值接收对象
            this.receiveTypeChange(data.body.notice.receiveType)
            this.spinShow = false
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        /*图片初始化展示处理赋值Ids*/
        values.images.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
        /*文件初始化展示处理赋值Ids*/
        values.files.forEach(item => {
          this.formItem.fileIds.push(item.id)
        })
        this.form.setFieldsValue({
          title: this.formItem.notice.title,
          publishTime: moment(this.formItem.notice.publishTime),
          resume: this.formItem.notice.resume,
          content: this.formItem.notice.content,
          receiveType: this.formItem.notice.receiveType,
          receiveIds: this.formItem.notice.receiveIds,
          receiveNames: this.formItem.notice.receiveNames,
          ifAnswer: this.formItem.notice.ifAnswer,
          checkId: this.formItem.notice.checkId
        })
      },
      setVOFields (values) {
        this.formItem.notice.title = values.title
        this.formItem.notice.publishTime = values.publishTime
        this.formItem.notice.resume = values.resume
        this.formItem.notice.content = values.content
        this.formItem.notice.receiveType = values.receiveType
        this.formItem.notice.receiveIds = values.receiveIds
        this.formItem.notice.receiveNames = values.receiveNames
        this.formItem.notice.ifAnswer = values.ifAnswer
        this.formItem.notice.checkId = values.checkId
      },
      submitAdd () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.platBase.notice.create,
                data: this.formItem,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  //清空表单数据
                  this.formItem = noticeVO()
                  this.$emit('addSuccess', data.body)
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          })
      },
      submitEdit () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.platBase.notice.update,
                data: this.formItem,
                params: {
                  id: this.formItem.notice.id
                },
                success: (data) => {
                  //清空表单数据
                  this.formItem = noticeVO()
                  this.$emit('editSuccess', data.body)
                }
              })
            }
          }
        )
      },
      onChange (a, b, c) {
      },
      loadUsersData () {
        this.$http(this, {
          url: SERVICE_URLS.user.userOrgJob.orgUser,
          noTips: true,
          success: (data) => {
            this.usersData = data.body
          }
        })
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.formItem.fileIds = []
        data.forEach(item => {
          this.formItem.fileIds.push(item.id)
        })
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>